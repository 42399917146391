<template>
  <!-- Pricing  Area -->
  <div class="inner-service-banner">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-7 col-xl-8 col-lg-10 col-md-10">
          <div class="section-heading-14 text-center">
            <h2>{{ t("pricing.title") }}</h2>
            <p>{{ t("pricing.subTitle") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Table -->
  <div class="inner-pricing-area-1">
    <div class="container">
      <div class="row justify-content-center inner-pricing-area-1-items">
        <!-- Plan 1 Llama Luv -->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-10">
          <div class="price-card">
            <div class="price-top-area d-flex justify-content-between">
              <div class="price-type">
                <h4>{{ t("pricing.plan1name") }}</h4>
                <span>{{ t("pricing.plan1subTitle") }}</span>
              </div>
              <div class="price-per-month">
                <h4>{{ t("pricing.plan1price") }}</h4>
                <span>{{ t("pricing.recurrence") }}</span>
              </div>
            </div>
            <div class="price-item-list">
              <ul class="list-unstyled">
                <!-- Feature 1 -->
                <li>
                  {{ t("pricing.plan1feature1")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 2 -->
                <li>
                  {{ t("pricing.plan1feature2")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 3 -->
                <li>
                  {{ t("pricing.plan1feature3")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 4 -->
                <li>
                  {{ t("pricing.plan1feature4")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 5 -->
                <li>
                  {{ t("pricing.plan1feature5")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 6 -->
                <li class="del">
                  {{ t("pricing.plan1feature6")
                  }}<font-awesome-icon :icon="['fas', 'times']" />
                </li>
                <!-- Feature 7 -->
                <li class="del">
                  {{ t("pricing.plan1feature7")
                  }}<font-awesome-icon :icon="['fas', 'times']" />
                </li>
              </ul>
            </div>
            <div class="price-area-btn">
              <!-- <a href="#" class="btn">Get started</a> -->
            </div>
          </div>
        </div>
        <!-- Plan 2 - Llama Tastic -->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-10">
          <div class="price-card professional-price-card">
            <div class="price-top-area d-flex justify-content-between">
              <div class="price-type">
                <h4>{{ t("pricing.plan2name") }}</h4>
                <span>{{ t("pricing.plan2subTitle") }}</span>
              </div>
              <div class="price-per-month">
                <h4>£ 79</h4>
                <span>{{ t("pricing.recurrence") }}</span>
              </div>
            </div>
            <div class="price-item-list">
              <ul class="list-unstyled">
                <!-- Feature 1 -->
                <li>
                  {{ t("pricing.plan2feature1")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 2 -->
                <li>
                  {{ t("pricing.plan2feature2")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 3 -->
                <li>
                  {{ t("pricing.plan2feature3")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 4 -->
                <li>
                  {{ t("pricing.plan2feature4")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 5 -->
                <li>
                  {{ t("pricing.plan2feature5")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 6 -->
                <li>
                  {{ t("pricing.plan2feature6")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 7 -->
                <li>
                  {{ t("pricing.plan2feature7")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
              </ul>
            </div>
            <div class="price-area-btn">
              <!-- <a href="#" class="btn">Get started</a> -->
            </div>
          </div>
        </div>
        <!-- Plan 3 - Llama Max -->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-10">
          <div class="price-card">
            <div class="price-top-area d-flex justify-content-between">
              <div class="price-type">
                <h4>{{ t("pricing.plan3name") }}</h4>
                <span>{{ t("pricing.plan3subTitle") }}</span>
              </div>
              <div class="price-per-month">
                <h4>£ 199</h4>
                <span>{{ t("pricing.recurrence") }}</span>
              </div>
            </div>
            <div class="price-item-list">
              <ul class="list-unstyled">
                <!-- Feature 1 -->
                <li>
                  {{ t("pricing.plan3feature1")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 2 -->
                <li>
                  {{ t("pricing.plan3feature2")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 3 -->
                <li>
                  {{ t("pricing.plan3feature3")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 4 -->
                <li>
                  {{ t("pricing.plan3feature4")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 5 -->
                <li>
                  {{ t("pricing.plan3feature5")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 6 -->
                <li>
                  {{ t("pricing.plan3feature6") }}
                  <font-awesome-icon :icon="['fas', 'check']" />
                </li>
                <!-- Feature 7 -->
                <li>
                  {{ t("pricing.plan3feature7")
                  }}<font-awesome-icon :icon="['fas', 'check']" />
                </li>
              </ul>
            </div>
            <div class="price-area-btn">
              <!-- <a href="#" class="btn">Get started</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "PricingTable",
  setup() {
    const { t, locale } = useI18n();

    return {
      locale,
      t,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
