import {
    createRouter,
    createWebHistory,
    RouterView
} from "vue-router";

import i18n, {
    SUPPORTED_LOCALES,
    getDefaultLocale,
    loadLocaleMessages
} from "@/i18n";

import Home from "@/views/Home.vue";
import FAQ from "@/views/FAQ.vue";
import Impressum from "@/views/Impressum.vue";
import CookiesPolicy from "@/views/CookiesPolicy.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue"
import TermsAndConditions from "@/views/TermsAndConditions.vue"
import ConfirmEmail from "@/views/ConfirmEmail.vue"
import DownloadApp from "@/views/DownloadApp.vue"
import QrCodePage from "../views/QrCodePage.vue";

const routes = [
    {
        path: "/:locale?",
        component: RouterView,
        beforeEnter(to, from, next) {
            const paramLocale = to.params.locale;

            if (
                !SUPPORTED_LOCALES.includes(paramLocale)
            ) {
                return next(getDefaultLocale());
            }

            loadLocaleMessages(
                i18n.global,
                paramLocale
            ).then(() => {
                if (
                    i18n.global.locale.value !== paramLocale
                ) {
                    i18n.global.locale.value = paramLocale;
                    document
                        .querySelector("html")
                        .setAttribute("lang", paramLocale);
                }

                return next();
            });
        },
        children: [
            {
                path: "",
                name: "Home",
                component: Home,
                alias: ""
            },
            {
                path: "faq",
                name: "FAQ",
                component: FAQ
            },
            {
                path: "impressum",
                name: "Impressum",
                component: Impressum
            },
            {
                path: "cookiespolicy",
                name: "CookiesPolicy",
                component: CookiesPolicy
            },
            {
                path: "privacypolicy",
                name: "PrivacyPolicy",
                component: PrivacyPolicy
            },
            {
                path: "termsandconditions",
                name: "TermsAndConditions",
                component: TermsAndConditions
            },
            {
                path: "confirmemail",
                name: "ConfirmEmail",
                component: ConfirmEmail
            },
            {
                path: "downloadapp",
                name: "DownloadApp",
                component: DownloadApp
            },
            {
                path: "qrcode",
                name: "QrCodePage",
                component: QrCodePage,
                meta: { hidden: true } 
            },
            {
                path: "watchsetupvideo",
                name: "watchsetupvideo",
                beforeEnter() {
                    window.location.replace("https://youtu.be/I6Mett4JKsw?si=BebpFVVJnHVuOmt6")
                }
                
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;


// const routes: Array<RouteRecordRaw> = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   // {
//   //   path: '/about',
//   //   name: 'About',
//   //   // route level code-splitting
//   //   // this generates a separate chunk (about.[hash].js) for this route
//   //   // which is lazy-loaded when the route is visited.
//   //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   // },
//   {
//     path: '/faq',
//     name: 'FAQ',
//     component: FAQ
//   },

//   {
//     path: '/privacypolicy',
//     name: 'PrivacyPolicy',
//     component: PrivacyPolicy
//   },
//   {
//     path: '/impressum',
//     name: 'Impressum',
//     component: Impressum
//   },
//   {
//     path: '/termsandconditions',
//     name: 'TermsAndConditions',
//     component: TermsAndConditions
//   },
//   {
//     path: '/cookiespolicy',
//     name: 'CookiesPolicy',
//     component: CookiesPolicy
//   },
//   {
//     path: '/localizationdemo',
//     name: 'LocalizationDemo',
//     component: LocalizationDemo
//   }
// ]

// const router = createRouter({
//   scrollBehavior: () => {
//     // always scroll to top
//     return { top: 0 }
//   },
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

// export default router
