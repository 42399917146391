<template>
  <div>
    <lightgallery
      :settings="{
        speed: 500,
        plugins: plugins,
        download: false,
        counter: false,
        autoplayFirstVideo: true,
        youtubePlayerParams: {
          modestbranding: 1,
          showinfo: 0,
          controls: 0,
          rel: 0,
          autoplay: 1,
        },
      }"
    >
      <a
        data-lg-size="1280-720"
        data-src="https://www.youtube.com/watch?v=BPET8VcPFCc&list=PLL_96b5KXZRgIX2iCNZfH8IRB3Upy39bO"
      >
        <img
          className="img-responsive"
          src="https://img.youtube.com/vi/scclkWj0ot0/0.jpg"
        />
        <div class="d-inline-block video-icon d-inline-block">
          <font-awesome-icon :icon="['fas', 'play']"  />
        </div>
      </a>
    </lightgallery>
  </div>
</template>
<script >
import Lightgallery from "lightgallery/vue";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import lgAutoPlay from "lightgallery/plugins/autoplay";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-video.css";
import "lightgallery/css/lg-autoplay.css";

let lightGallery = null;

export default {
  name: "VideoPlayer",
  components: {
    Lightgallery,
  },
  watch: {
    items(newVal, oldVal) {
      this.$nextTick(() => {
        lightGallery.refresh();
      });
    },
  },
  data: () => ({
    plugins: [lgThumbnail, lgZoom, lgVideo, lgAutoPlay],
    items: [
      {
        id: "1",
        size: "1280-720",
        src: "https://www.youtube.com/watch?v=BPET8VcPFCc&list=PLL_96b5KXZRgIX2iCNZfH8IRB3Upy39bO",
        thumb: "https://img.youtube.com/vi/scclkWj0ot0/0.jpg",
        subHtml: `<div class="lightGallery-captions">
                <h4>Test</h4>
                <p>Published on November 13, 2018</p>
            </div>`,
      },
    ],
  }),
  // methods: {
  //   onInit: () => {
  //     console.log("lightGallery has been initialized");
  //   },
  //   onBeforeSlide: () => {
  //     console.log("calling before slide");
  //   },
  // },
  props: {
    msg: String,
  },
};
</script>

<style scoped>
.gallery-item {
  margin: 2px;
}
</style>