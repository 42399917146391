<template>
  <div class="content-area-l-12-1">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-10 col-xl-10">
          <div class="section-heading-14 text-center">
            <p>
              {{ t("howtogetit.subTitle") }}
            </p>
            <br />
            <div class="row justify-content-lg-center w-100">
              <div class="apps-btn">
                <a  @click="handleAppleStoreClick();"
                  href="https://apps.apple.com/ch/app/llama-points/id1589949105?platform=iphone" target="_blank"
                  class="app-store"><img src="images/l3/app-store.png" alt="" /></a>
                <a @click="handleGooglePlayClick();"
                  href="https://play.google.com/store/apps/details?id=com.bfmsolutions.LlamaPoints" target="_blank"
                  class="app-store"><img src="images/l3/google-play.png" alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "HowToGetIt",
  setup() {
    const { t, locale } = useI18n();

    // Handler for the Apple Store link
    function handleAppleStoreClick() {
      window.fbq('trackCustom', 'DownloadAppleApp', { promotion: 'apple_store_visit' });
      window.gtag_report_conversion_apple();
      window.open("https://apps.apple.com/ch/app/llama-points/id1589949105?platform=iphone", "_blank");
    }

    // Handler for the Google Play link
    function handleGooglePlayClick() {
      window.fbq('trackCustom', 'DownloadGoogleApp', { promotion: 'google_play_visit' });
      window.gtag_report_conversion_google();
      window.open("https://play.google.com/store/apps/details?id=com.bfmsolutions.LlamaPoints", "_blank");
    }

    return {
      locale,
      t,
      handleAppleStoreClick,
      handleGooglePlayClick,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
