<!-- FAQ -->

<template>
  <div class="site-wrapper overflow-hidden position-relative">
    <div class="inner-terms-banner">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-10">
            <div class="section-heading-14 text-center">
              <h2>{{ t ("faq.header")}}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content Area -->

    <div class="topics-area-l-12 position-relative">
      <div class="topics-area-l12-shape-1">
        <img src="images/l6/shape-4.1.svg" alt="" class="w-100" />
      </div>
      <div class="topics-area-l12-shape-2">
        <img src="images/l6/shape-5.svg" alt="" class="w-100" />
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xxl-7 col-xl-8 col-lg-9">
            <div class="faq-main-area-single" id="general">
              <h2>{{ t("faq.businessHeader") }}</h2>
              <vue-collapsible-panel-group accordion>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ1") }}</template>
                  <template #content>{{ t("faq.businessA1") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ2") }}</template>
                  <template #content>{{ t("faq.businessA2") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ3") }}</template>
                  <template #content>{{ t("faq.businessA3") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ4") }}</template>
                  <template #content>{{ t("faq.businessA4") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ5") }}</template>
                  <template #content>{{ t("faq.businessA5") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ6") }}</template>
                  <template #content>{{ t("faq.businessA6") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ7") }}</template>
                  <template #content>{{ t("faq.businessA7") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ8") }}</template>
                  <template #content>{{ t("faq.businessA8") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ9") }}</template>
                  <template #content>{{ t("faq.businessA9") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ10") }}</template>
                  <template #content>{{ t("faq.businessA10") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ11") }}</template>
                  <template #content>{{ t("faq.businessA11") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ12") }}</template>
                  <template #content>{{ t("faq.businessA12") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ13") }}</template>
                  <template #content>{{ t("faq.businessA13") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ14") }}</template>
                  <template #content>{{ t("faq.businessA14") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.businessQ15") }}</template>
                  <template #content>{{ t("faq.businessA15") }}</template>
                </vue-collapsible-panel>
              </vue-collapsible-panel-group>
            </div>
          </div>
          <div>
            <HowToGetIt />
          </div>
          <div class="col-xxl-7 col-xl-8 col-lg-9">
            <div class="faq-main-area-single" id="general">
              <h2>{{ t("faq.userHeader") }}</h2>
              <vue-collapsible-panel-group accordion>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.userQ1") }}</template>
                  <template #content>{{ t("faq.userA1") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.userQ2") }}</template>
                  <template #content>{{ t("faq.userA2") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.userQ3") }}</template>
                  <template #content>{{ t("faq.userA3") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.userQ4") }}</template>
                  <template #content>{{ t("faq.userA4") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>{{ t("faq.userQ5") }}</template>
                  <template #content>{{ t("faq.userA5") }}</template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="true" id="deleteAccount">
                  <template #title>{{ t("faq.userQ6") }}</template>
                  <template #content>{{ t("faq.userA6") }}</template>
                </vue-collapsible-panel>
              </vue-collapsible-panel-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from "@dafcoe/vue-collapsible-panel";
import "@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css";

import HowToGetIt from "@/components/HowToGetIt.vue";

export default {
  name: "FAQ",
  components: { VueCollapsiblePanelGroup, VueCollapsiblePanel, HowToGetIt },
  setup() {
    const { t, locale } = useI18n();

    return {
      locale,
      t,
    };
  },
};
</script>


<style scoped>
</style>
